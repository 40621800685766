import Axios from "lib/Axios";
import { APIResponse, Meta } from "types/ApiResponse";
import { BreadCrumbs } from "types/BreadCrumbs";
import {
  FooterResponse,
  HomePage,
  LastCategories,
  OnBoardingCategoriesResponse,
  StatisticsCountResponse,
} from "types/homepage";
import { Package } from "types/Package";

export const _GetPackages = (id: number) =>
  Axios.get<APIResponse<Package[], Meta>>(`/v1/packages?category=${id}`).then((res) => res.data);

export const _GetHomePageData = (params: { categories?: number }, token?: string) =>
  Axios.get<APIResponse<HomePage, Meta>>("/v1/home", {
    ...(token
      ? {
          params,
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _GetLastCategories = (id: number, pageNumber: number) =>
  Axios.get<LastCategories>(`/v1/categories/${id}/subjects?page=${pageNumber}`).then(({ data }) => data);

export const _GetBreadCrumbs = (slug?: string) =>
  Axios.get<APIResponse<BreadCrumbs[], Meta>>(`/v1/breadcrumbs/${slug}/hierarchy`).then((res) => res.data);

export const _GetCategories = (categoryId?: number) =>
  Axios.get<APIResponse<OnBoardingCategoriesResponse, Meta>>(
    `/v1/onboarding-steps/categories${categoryId ? "/" + categoryId : ""}`
  ).then((res) => res.data);

export const _GetFooterData = ({ country, locale }: { country: string; locale: string }) =>
  Axios.get<APIResponse<FooterResponse, {}>>(`/v1/footer`, {
    headers: {
      country,
      "Accept-Language": locale,
    },
  }).then(({ data }) => data);

export const getStatisticsCount = () =>
  Axios.get<APIResponse<StatisticsCountResponse, {}>>(`/v1/statistics/counts`).then(({ data }) => data.data);
